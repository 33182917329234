body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: red; */
}
body {
  --amplify-colors-background-secondary: url('/src/images/bg_texture-dust-white.jpg')
      0 0 repeat,
    #e0dfd7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* remove sign in */
div[data-amplify-container] {
  padding: 2rem !important;
}
.amplify-tabs.amplify-flex {
  display: none;
}

.amplify-flex.federated-sign-in-container {
  padding: 3rem 0 1rem 0 !important;
  display: block !important;
  overflow-y: hidden;
}
.amplify-flex.federated-sign-in-container + .amplify-flex {
  display: none;
}

[data-amplify-form] {
  padding: 0 !important;
}

[data-or-container].amplify-flex {
  display: none;
}

[data-amplify-footer] {
  display: none;
}

[data-amplify-authenticator] [data-amplify-router] {
  background: transparent !important;
  box-shadow: none !important;
  border: none !important;
}

.amplify-divider {
  display: none !important;
}

[data-amplify-authenticator][data-variation='modal'] {
  background: transparent !important;
}

[data-amplify-authenticator][data-variation='modal']
  [data-amplify-router-content] {
  background: transparent !important;
}

.federated-sign-in-button.amplify-button {
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
  text-align: left;
  display: inline-block;
  width: 200px !important;
  font-family: 'Brandon Grotesque Black', Arial, Helvetica, sans-serif;
}
.federated-sign-in-button.amplify-button::after {
  content: 'Log in with Google';
  width: 200px !important;
  height: 40px !important;
  display: inline-block;
  line-height: 40px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  text-align: center !important;
  color: #fff !important;
  background: #f1b64f !important;
  border-radius: 1px !important;
  text-transform: uppercase;
  padding: 5px 0px;
  font-weight: bold;
  transition: background 0.2s ease-in-out;
}
.federated-sign-in-button.amplify-button:hover::after {
  background: #fb4640 !important;
}

.federated-sign-in-button.amplify-button:hover {
  background: transparent !important;
  color: #fb4640;
}

.federated-sign-in-button.amplify-button .amplify-text {
  display: none;
}
.federated-sign-in-button.amplify-button svg {
  display: none;
}

.federated-sign-in-button.amplify-button span {
  font-family: TT Commons, Helvetica, Arial, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #fb4640;
  visibility: hidden;
  position: relative;
}
